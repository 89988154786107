import React, { useContext } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { ThemeContext } from "providers/ThemeProvider";
import { Container, Button } from "components/common";
import dev from "assets/illustrations/skills.svg";
import { Wrapper, SkillsWrapper, Details, Thumbnail } from "./styles";
import {
img
} from 'data/config';
export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img
            src={img}
            alt="I’m Luis A.Tavarez and  i'm Full-Stack Developer!"
          />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <kbd>
            I am a software developer, I am currently studying software at ITLA
            University (Institute Technology Of The America), Too Have multiples
            knowledge in different field (Electronic ,Electricity ,Networking
            ,basic knowledge in security), Have mutiples knowledge in diferent
            Techonology of Sotfware :Python,C++,C#,PHP,JAVASCRIPT(HTML + CSS),
            experince in git(gitlab,github) and Docker.
            <div>
              <b>*Best skills</b>
              <div> #Python - Django/Flask</div>
              <div> #Javascript/Typescrip -Nodejs/Denojs</div>
              <div> #C# - Asp.net/net.core</div>
              <div> #C++</div>
              <div> #Go </div>
            </div>
          </kbd>
          <p></p>
          <Button as={AnchorLink} href="#contact">
            Contact me
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
