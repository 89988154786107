import React, { useContext,useEffect, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Star from 'components/common/Icons/Star';
import Fork from 'components/common/Icons/Fork';
import { Wrapper, Grid, Item, Content, Stats, Languages } from './styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);
  const [repos,setRepos]= useState([]);
  const [reposCount,setReposCount]= useState([]);
 

  useEffect(async()=>{
      let myRepos;
      const data= sessionStorage.getItem("repos");
      if(data){
          myRepos= JSON.parse(data);
          setReposCount(myRepos.length);

          myRepos = myRepos.slice(1,13);
          return setRepos(myRepos);
      }
      
      
     async function fetchRepos(){
          const response = await fetch("https://api.github.com/users/uppy19d0%20/repos");
           myRepos= await response.json();
          setReposCount(myRepos.length);
          sessionStorage.setItem("repos",JSON.stringify(myRepos));
          myRepos = myRepos.slice(1,13);
          setRepos(myRepos);
       }
       fetchRepos();

  },[])

  return (
    <Wrapper as={Container} id="projects">
      <h2>Projects</h2>
      <Grid>
        {repos.map(( node) => (
          <Item key={node.id} as="a" href={node.html_url} target="_blank" rel="noopener noreferrer" theme={theme}>
            <Card theme={theme}>
              <Content>
                <h4>{node.name}</h4>
                <p>{node.description}</p>
              </Content>
              <TitleWrap>
                <Stats theme={theme}>
                  <div>
                    <Star color={theme === "light" ? "#000" : "#fff"} />
                    <span>{node.stargazers_count}</span>
                  </div>
                  <div>
                    <Fork color={theme === "light" ? "#000" : "#fff"} />
                    <span>{node.forks_count}</span>
                  </div>
                </Stats>
                <Stats theme={theme}>
                  {/* <Languages>
                    {
                      node.languages.nodes.map(({ id, name }) => (
                        <span key={id}>
                          {name}
                        </span>
                      ))
                    }
                  </Languages> */}
                </Stats>
              </TitleWrap>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
