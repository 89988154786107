import React from 'react';
// import { Form, FastField } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from 'components/common';
import { Error, Center, InputField } from './styles';

export default () => (
      <form     
      name="Contact Form"
      method="POST"
      data-netlify-honeypot="bot-field"
      data-netlify="true">
      <input type="hidden" name="form-name" value="Contact Form" />
        <InputField>
          <Input
            type="text"
            name="name"
            aria-label="name"
            required
            placeholder="Full name*"
          />
        </InputField>
        <InputField>
          <Input
            id="email"
            aria-label="email"
            required
            type="email"
            name="email"
            placeholder="Email*"
          />
        </InputField>
        <InputField>
          <Input
            component="textarea"
            aria-label="message"
            id="message"
            rows="8"
            type="text"
            name="message"
            placeholder="Message*"
            required

          />
        </InputField>
        <Center>
          <Button secondary type="submit">
            Submit
          </Button>
        </Center>
      </form>


);
